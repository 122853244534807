@import url('https://fonts.googleapis.com/css2?family=Barlow:wght@400;500;600;700&display=swap');

:root {
    --brand: #ff4d29;
    --dark: #092032;
    --body: #516171;
    --border: rgba(0,0,0,0.08);
    --shadow: 0px 6px 30px rgba(0, 0, 0, 0.08);
    
}

body {
    font-family: "Barlow", sans-serif;
    color: var(--body);
    line-height: 1.7;
}

h1,h2,h3,h4,h5,h6,
.display-1,.display-2,.display-3,.display-4 {
    font-weight: 700;
    color: var(--dark);
}

.bg-cover {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

img {
    width: 100%;
}

a {
    color: var(--dark);
    transition: all 0.4s ease;
    font-weight: 500;
}

a:hover {
    color: var(--brand);
}

section {
    padding-top: 80px;
    padding-bottom: 80px;
}

.text-brand {
    color: var(--brand) !important;
}


.hero-slider .owl-prev,
.hero-slider .owl-next{
    background-color: rgba(255, 255, 255, 0.3) !important;
    width: 60px !important;
    height: 60px !important;
    display: block;
    display: grid;
    place-items: center;
    color: #fff !important;
    border-radius: 100px;
    line-height: 0;
    border-radius: 100px !important;
    position: absolute;
    top: 50%;
    font-weight: 600 !important;
    font-size: 12px !important;
    transition: all 0.4s ease;
    margin-top: -30px !important;
}

.owl-prev {
    left: 0;
}
.owl-next {
    right: 0;
}

.hero-slider .owl-prev:hover,
.hero-slider .owl-next:hover {
    background-color: var(--brand) !important; 
}

.owl-dot.active span{
    background-color: var(--brand) !important;
}


 /* slide */
 .slide {
     min-height: 100vh;
     display: flex;
     align-items: center;
     justify-content: center;
     position: relative;
}

.slide1 {
    background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(../img/bg_banner2.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.slide2 {
    background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(../img/bg_banner1.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.slide .display-3 {
    text-transform: uppercase;
    color: #fff;
}

/* Top Navbar */
.top-nav {
    background-color: var(--brand);
    color: #fff;
    padding: 5px 0;
}

.top-nav p {
    display: inline-block;
    margin-bottom: 5px;
    margin-right: 10px;
    font-size: 14px;
}

.top-nav span,
.top-nav i {
    vertical-align: middle;
}

.top-nav a{
    margin-left: 5px;
    text-decoration: underline;
    color: inherit;
}

.social-icons {
    margin-top: 5px;
}

.social-icons a {
    width: 28px;
    height: 28px;
    display: inline-flex;
    color: #fff;
    background-color: rgba(255, 255, 255, 0.25);
    text-decoration: none;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
    margin-right: 3px;
}

.social-icons a:hover {
    background-color: #fff;
    color: var(--brand);
}

.conditions-section {
    margin: 20px 0;
}

.conditions-section a {
    color: #fff;
    margin: 0 10px;
}

.navbar {
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Add shadow below the navbar */
}

.navbar .navbar-nav .nav-link {
    color: var(--dark);
}

.navbar .navbar-nav .nav-link:hover {
    color: var(--brand);
}

.navbar .navbar-nav .nav-link.active {
    color: var(--brand);
}

.navbar-brand {
    font-size: 28px;
    font-weight: 700;
    display: flex;
    align-items: center;
}

/* Responsive logo */
.logo-img {
    max-height: 55px;
    height: auto;
    width: auto;
    max-width: 100%;
    padding: 5px 0;
}

/* Responsive text */
.slogan {
    font-size: 14px;
    font-style: italic;
}

/* Responsive adjustments */
@media (max-width: 767.98px) {
    .navbar-brand {
        flex-direction: row;
        align-items: center;
        text-align: left;
    }

    .logo-img {
        max-height: 45px;
    }

    .navbar-brand h5 {
        font-size: 18px;
    }

    .slogan {
        font-size: 12px;
    }
    .top-nav .row {
        text-align: center;
        flex-direction: column;
    }

    .top-nav .col-md-auto {
        text-align: center;
    }

    .social-icons {
        margin-bottom: 5px;
    }

    .top-nav p {
        font-size: 13px;
    }
    .top-nav {
        padding: 1px 0;
    }
}


.btn-brand {
    border-color: var(--brand);
    background-color: var(--brand);
    color: #fff;
}

.btn-brand:hover {
    background-color: #d64022;
    border-color: #d64022;
    color: #fff;
}

.intro {margin-bottom: 36px;
text-align: center;}

.intro p {
    max-width: 500px;
}
.intro h6{
    color: var(--brand);
    font-weight: 400;
    text-transform: uppercase;
}

.intro h1 {
    margin-top: 15px;
    margin-bottom: 15px;
}

.info-box {
    align-items: center;
    display: flex;
}

.info-box img {
    width: 90px;
}


#milestone {
    background: linear-gradient(rgba(255, 77, 41, 0.85), rgba(255, 77, 41, 0.85)), url(../img/bg_banner1.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

#milestone h1,
#milestone p {
    color: #fff;
}

.service {
    padding: 32px;
    background-color: #fff;
    box-shadow: var(--shadow);
}

.service h5 {
    margin-top: 24px;
    margin-bottom: 14px;
}

.service img {
    width: 90px;
}


.project {
    position: relative;
    overflow: hidden;
    z-index: 2;
}

.project h6 {
    font-weight: 400;
}

.project h6::before {
    content: "";
    height: 2px;
    width: 30px;
    display: inline-block;
    background: var(--brand);
    vertical-align: middle;
    margin-right: 10px;
}


.project .overlay {
    width: 100%;
    height: 220px;
    position: absolute;
    bottom: 0;
    left: 0;
    background: linear-gradient(180deg, rgba(255, 76, 41, 0) 0%, var(--dark) 100%);
}

.project .content {
    position: absolute;
    left: 10%;
    bottom: 10%
}

.project h2,
.project h6 {
    color: #fff;
}

.team-member {
    text-align: center;
}

.team-member .image{
    position: relative;
    z-index: 2;
    overflow: hidden;
}

.team-member .overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: -10%;
    background-color: rgba(255, 77, 41, 0.7);
    opacity: 0;
    transition: all 0.4s ease;
}

.team-member h5 {
    margin-top: 16px;
    margin-bottom: 4px;
}

.team-member .social-icons {
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    opacity: 0;
    transition: all 0.4s ease;
}

.team-member .social-icons a {
    width: 40px;
    height: 40px;
}

.team-member:hover .social-icons {
    top: 50%;
    opacity: 1;
}

.team-member:hover .overlay {
    top: 0%;
    opacity: 1;
}

#reviews {

    background: linear-gradient(-90deg, rgba(8, 32, 50, 0.8), rgba(8, 32, 50, 0.8)), url(../img/bg_banner1.jpg), #082032;;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.review {
    text-align: center;
    z-index: 2;
    position: relative;
    margin: 15px;
    max-width: 768px;
    margin: auto;
}

.review .bxs-quote-alt-left {
    font-size: 120px;
    position: absolute;
    opacity: 0.1;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
}

.review img {
    width: 80px !important;
    height: 80px;
    border-radius: 100px;
    margin: auto;
}

.review h5 {
    margin-top: 16px;
    margin-bottom: 4px;
    color: #fff;
}

.review h3 {
    margin-top: 26px;
    margin-bottom: 26px;
    font-size: 22px;
    color: #fff;
    font-weight: 400;
    line-height: 1.7;
}

.review small {
    color: var(--brand);
}

.review .stars {
    color: var(--brand);
}

.blog-post {
    position: relative;
    background-color: #fff;
    box-shadow: var(--shadow);
}

.blog-post .content {
    padding: 32px;
}

.blog-post a {
    position: absolute;
    top: 20px;
    left: 20px;
    background-color: var(--brand);
    padding: 2px 12px;
    border-radius: 100px;
    text-decoration: none;
    color: #fff;
}

.blog-post h5 {
    margin-top: 12px;
    margin-bottom: 12px;
}

.blog-post small {
    text-transform: uppercase;
    color: var(--brand);
    text-decoration: underline;
}

footer {
    background: linear-gradient(0deg, rgba(8, 32, 50, 0.9), rgba(8, 32, 50, 0.9)), url('../img/bg_banner1.jpg'), #082032;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    color: #fff;
}

.footer-top {
    padding: 80px 0;
    display: flex; /* Default layout for desktop view */
    justify-content: space-between;
    flex-wrap: wrap;
}

.footer-box {
    margin-bottom: 30px;
    flex: 1;
    min-width: 250px; /* Ensures that the columns don't shrink too much */
}

.footer-box h2.widget-title {
    font-size: 20px;
    color: #fff;
    margin-bottom: 20px;
    font-weight: bold;
}

.footer-box p,
.footer-box ul {
    color: #ADB3B9;
}

.footer-box ul {
    list-style: none;
    padding: 0;
    margin: 0;
    text-align: center; /* Center-align the text */
}

.footer-box ul li {
    margin-bottom: 10px;
    display: flex;
    justify-content: center; /* Center-align list items */
    align-items: center;
}

.footer-box ul li a {
    color: #ADB3B9;
    text-decoration: none;
    margin-left: 5px;
}

.footer-box ul li a:hover {
    text-decoration: underline;
}

.footer-bottom {
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    padding: 20px 0;
    text-align: center;
}

.footer-bottom p {
    color: #ADB3B9;
    margin: 0;
}

.footer-bottom a {
    color: #fff;
    text-decoration: none;
}

.footer-bottom a:hover {
    text-decoration: underline;
}

input[type="file"].form-control {
    height: auto;
}

/* Responsive Design */
@media (max-width: 767px) {
    .footer-top {
        display: grid; /* Switch to grid layout on mobile view */
        grid-template-columns: 1fr; /* Stacks items in a single column */
        gap: 20px; /* Adds space between the grid items */
    }

    .footer-box {
        text-align: center; /* Centers content in mobile view */
    }

    .footer-box ul {
        text-align: center;
    }

    .footer-box ul li {
        justify-content: center; /* Centers list items on mobile view */
    }
}


.loader {
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: red;
    z-index: 99999;
    position: fixed;
    left: 0;
    right: 0;
}


input.form-control {
    border-color: transparent;
    height: 44px;
}

.form-control {
    background-color: rgba(0, 0, 0, 0.04);
    border-color: rgba(0, 0, 0, 0.04);;
}

.form-control:focus {
    box-shadow: none;
    border-color: var(--brand);
}


.carousel-item-padding {
    padding: 0 11px; /* Adds 10px padding on the left and right */
}

.carousel-container {
    overflow: hidden;
    padding: 0; /* Ensure no extra padding */
    margin: 0 auto; /* Center the carousel */
    width: 100%;
}


.img-fixed-size {
    width: auto;
    height: 150px;
    object-fit: fill;
}

.responsive-map {
    overflow: hidden;
    height: 0;
    position: relative;
    padding-bottom: 30%;
}

.responsive-map iframe{
    border: 0;
    height: 100%;
    width: 100%;
    left: 0;
    /* top: 1; */
    position: absolute;
}

.background-color-grey{
    background: linear-gradient(to right, #f0f4f8, #d9e2ec);
}


/* CustomPhoneInput.css */
.phone-input-container {
    display: flex;
    width: 100%;
  }

  .phone-input-container .form-control {
    background-color: #e9ecef; /* Match your custom background color */
    border: 1px solid #ced4da; /* Match border style */
    border-radius: 0 0.25rem 0.25rem 0; /* Right corners rounded */
    margin-left: -1px; /* Remove the gap between the prefix and input */
  }

  .input-group-text {
    background-color: #e9ecef; /* Match your custom background color */
    border: 1px solid #ced4da; /* Match border style */
    border-radius: 0.25rem 0 0 0.25rem; /* Left corners rounded */
  }

  .phone-input-container .d-none {
    display: none !important; /* Ensures dropdown and flag are hidden */
  }

  .react-tel-input .form-control {
    width: 100% !important; /* Override background color */
    background: linear-gradient(to right, #f0f4f8, #d9e2ec) !important;
    line-height: 1.5 !important;
    height: 44px !important;
  }





#products .product-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    border: 1px solid #ddd; /* Optional: adds a border around the card */
    border-radius: 8px; /* Optional: adds rounded corners */
    overflow: hidden; /* Ensures no overflow from child elements */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional: adds a shadow for better visual effect */
    margin-bottom: 2rem; /* Space below each product item */
  }

  #products .blog-post {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    border: 1px solid #ddd; /* Optional: adds a border around the card */
    border-radius: 8px; /* Optional: adds rounded corners */
    overflow: hidden; /* Ensures no overflow from child elements */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional: adds a shadow for better visual effect */
    margin-bottom: 2rem; /* Space below each product item */
  }

  #products .blog-post img {
    width: 100%;
    height: 200px; /* Fixed height */
    object-fit: cover; /* Ensures the image covers the entire container without distortion */
    margin-bottom: 1rem; /* Space below the image */
  }

  #products .content {
    padding: 1rem;
  }

  #products h5 {
    font-size: 1.25rem;
    margin-bottom: 0.5rem;
  }

  #products p {
    color: #666;
  }

  /* Add margin between columns */
  .row > .col-md-4 {
    padding: 0 1rem; /* Horizontal padding for spacing between columns */
  }

  /* Ensure proper spacing on smaller screens */
  @media (max-width: 768px) {
    #products .col-md-4 {
      flex: 0 0 100%;
      max-width: 100%;
      padding: 0 0.5rem; /* Less horizontal padding on smaller screens */
    }
  }

  .category-page .list-unstyled {
    padding: 0;
  }

  .category-page .list-unstyled li {
    border-bottom: 1px solid #ddd; /* Adds a bottom border for better separation */
  }

  .category-page .d-inline-block {
    display: inline-block;
    width: 100%;
  }

  .category-page .bg-light {
    background-color: #f8f9fa; /* Light background for list items */
  }

  .blog-post:hover {
    transform: scale(1.05); /* Slightly enlarges the card on hover */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Adds shadow to card on hover */
  }

  .category-page {
    background-color: #f8f9fa; /* Light background color */
    padding: 2rem 0; /* Vertical padding */
  }

  .product-card {
    background-color: #ffffff; /* White background for cards */
    border: 1px solid #dee2e6; /* Light border color */
    border-radius: 0.375rem; /* Rounded corners */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow */
    transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth transition */
  }

  .product-card:hover {
    transform: translateY(-5px); /* Lift card on hover */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Deeper shadow */
    background-color: #ff4d29; /* Background color on hover */

  }

  .product-name {
    font-size: 1.25rem; /* Font size for product names */
    font-weight: 500; /* Slightly bold font */
    color: #333333; /* Darker text color */
    margin-bottom: 0; /* Remove bottom margin */
  }

  .whatsapp-chat {
    position: fixed;
    right: 35px;
    bottom: 30px;
    z-index: 101;
  }
  
  .whatsapp-icon {
    background: #25d366;
    border-radius: 50%;
    padding: 10px;
    box-shadow: 0 10px 10px 4px rgb(0, 0, 0, 0.12);
    cursor: pointer;
    z-index: 101;
  }
  
  .whatsapp-icon i {
    color: #fff;
  }
  
  .whChatSec {
    position: fixed;
    right: 35px;
    bottom: 75px; /* Adjust to position the chat box above the icon */
    width: 350px;
    background: #fff;
    overflow: hidden;
    box-shadow: 0 10px 10px 4px rgb(0, 0, 0, 0.12);
    opacity: 0;
    transition: all ease-in-out 0.4s;
    z-index: 100;
    visibility: hidden;
    border-radius: 10px; /* Rounded corners for the entire chat box */
  }
  
  .whChatSec.wh-active {
    display: block;
    opacity: 1;
    bottom: 120px; /* Adjust this if necessary */
    visibility: visible;
  }
  
  .whHdSec {
    background-color: #25d366; /* Green background */
    color: #fff; /* White text */
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-radius: 10px; /* Adjust this value for more or less rounding */
  }
  
  
  .whLogo {
    margin-right: 15px; /* Space between logo and text */
  }
  
  
  .whText h4 {
    margin: 0;
    color: #fff; /* White color for 'Raise your Query' */
  }
  
  .whText p {
    margin: 0;
  }
  
  .whChatCont {
    background-color: #f8f9fa; /* Light grey background */
    padding: 15px;
  }
  
  .whChatList {
    display: flex;
    background-color: #fff; /* White background for the chat link container */
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  }
  
  .chat-link {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #000; /* Default color for the link text */
    flex: 1; /* Make sure the content fills available space */
  }
  
  .green-block {
    width: 3px; /* Width of the green block */
    background-color: #25d366; /* Green color */
    height: 100%; /* Full height of the chat link container */
    margin-right: 10px; /* Space between the green block and content */
  }
  
  .chat-link i {
    margin-right: 10px; /* Space between the logo and text */
    transition: color 0.3s ease;
  }
  
  .chat-text {
    display: flex;
    flex-direction: column; /* Stack items vertically */
    align-items: flex-start; /* Ensure texts align to the left */
  }
  
  .chat-link-text {
    color: #000; /* Default color for 'Click Here to Chat' */
    transition: color 0.3s ease;
  }
  
  .chat-company {
    color: #000; /* Default color for 'R H Enterprise' */
    margin-top: 5px; /* Space between the two lines of text */
    transition: color 0.3s ease;
  }
  
  /* Hover effects */
  .chat-link:hover {
    color: #ff4d29; /* Change color on hover */
  }
  
  .chat-link:hover i {
    color: #ff4d29; /* Change color of the logo on hover */
  }
  
  .chat-link:hover .chat-link-text {
    color: #ff4d29; /* Change color on hover */
  }
  
  .chat-link:hover .chat-company {
    color: #ff4d29; /* Change color on hover */
  }

  .grid-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 20px;
    justify-items: center;
  }
  
  .pdf-item-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 10px;
    cursor: pointer;
  }
  
  .pdf-item {
    width: 200px;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    margin-bottom: 10px;
  }
  
  .pdf-item h5 {
    margin: 0;
    font-size: 16px;
    text-align: center;
  }
  
  .pdf-item:hover {
    background-color: #eee;
  }
  